<template>
    <b-modal id="SipSettingsModal" ref="SipSettingsModal" title="Настройки SIP" size="md">
        <b-row class="mb-1">
            <b-col cols="12">
                Адрес сервера
            </b-col>
            <b-col cols="12">   
                <b-form-input v-model.trim="sipConfig.url" placeholder="Введите URL" size="sm"/>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                Порт сервера
            </b-col>
            <b-col cols="12">   
                <b-form-input v-model.trim="sipConfig.port" placeholder="PORT" size="sm"/>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                Номер пользователя
            </b-col>
            <b-col cols="12">
                <b-form-input v-model.trim="sipConfig.user" placeholder="Введите номер" size="sm"/>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                Пароль
            </b-col>
            <b-col cols="12">   
                <b-form-input v-model="sipConfig.secret" type="password" size="sm"/>
            </b-col>
        </b-row>
        <template #modal-footer>
            <button @click="saveSipConfig" class="btn btn-primary btn-sm">Сохранить</button>
        </template>
    </b-modal>
</template>
<script>
export default {
    data(){
        return{
            sipConfig: {
                url: '',
                port: '',
                user: '',
                secret: '',
            }
        }
    },
    mounted(){
        const config = localStorage.getItem('sipConfig')
        if (config) {
            this.sipConfig = JSON.parse(config)            
        }
    },
    methods: {
    saveSipConfig(){
        this.sipConfig.url = this.sipConfig.url.replaceAll(' ', '')
        this.sipConfig.port = this.sipConfig.port.replaceAll(' ', '')
        this.sipConfig.user = this.sipConfig.user.replaceAll(' ', '')
        localStorage.setItem('sipConfig', JSON.stringify(this.sipConfig))
        if (this.$store.state.caller.status) {
            this.$store.commit('caller/UPDATE_STATUS', false)
            setTimeout(()=>{
                this.$store.commit('caller/UPDATE_STATUS', true)
            }, 2000)
        }
        this.$refs['SipSettingsModal'].hide()
    }
    }
}
</script>