<template>
    <b-row>
        <b-col cols="2">
            <p>ID Заказа</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="conditionOptions" :value="value.filter_order_id_condition"
                @input="updateModel($event, 'filter_order_id_condition')"/>
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Введите ID Заказа" size="sm" :value="value.filter_order_id" @input="updateModel($event, 'filter_order_id')" />
        </b-col>
    </b-row>
</template>
<script>
import filterNumberOptions from "@/utils/constants/filerConstants/numberParametrs";
export default {
    props: ['value', 'field'],
    name: 'order_id',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            conditionOptions: filterNumberOptions
        }
    },
    methods: {
		updateModel(value, name) {
		 if (name === 'filter_order_id' || name === 'filter_order_id_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
	  },
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'order_id' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_order_id';
            this.updateModel(value, name);
        }
    },
    watch: {
        $route(to, from) {
            if(from.fullPath !== to.fullPath) {
                this.$store.state.filterPlus.filterPlus = null
            }
        },
    }
}
</script>