<template>
	<div>
		 <b-row>
			  <b-col cols="2">
					<p>{{ field.label }}</p>
			  </b-col>
			  <b-col cols="10">
					<b-form-select size="sm" :options="Options" :value="filterValue"
						 @input="updateModel($event, 'filter_status_performer')" />
			  </b-col>
		 </b-row>
	</div>
	</template>
	<script>
	export default {
		 props: ['value', 'field'],
		 name: 'status',
		 model: {
			  prop: 'value',
			  event: 'change'
		 },
		 data() {
			  return {
					Options: [
						 { value: 0, text: 'Не на смене'},
						 { value: 1, text: 'На смене' },
	
					],
					currentRoute: this.$route.fullPath,
					filterValue: this.value.filter_status_performer
			  }
		 },
		 methods: {
			  checkFilterPlus() {
			  const filterPlus = this.$store.state.filterPlus;
			  if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'status_auto' && this.$store.state.filterPlus.addFilterPlus) {
				 const value = filterPlus.filterPlus;
				 const name = 'filter_status_performer';
	  
				 const selectedType = this.Options.find(status_auto => status_auto.text === value);
	  
				 if (selectedType) {
					this.filterValue = selectedType.value;
					this.updateModel(selectedType.value, name);
				 }
			  }
			},
			  updateModel(value, name) {
					this.$emit('change', { val: value, field: name })
			  },
			  
		 },
		 mounted() {
			this.checkFilterPlus();
		 },
		 watch: {
			  '$store.state.filterPlus.filterPlus'(newValue) {
					if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'status_auto' && this.$store.state.filterPlus.addFilterPlus) {
					this.checkFilterPlus();
			  }
			}
		 }
	}
	</script>