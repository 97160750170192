import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

export const canGetAccess = (to) => {
    let userData = JSON.parse(localStorage.getItem('userData'))
    let result = false
    if (userData) {
        userData.access.forEach(el=>{
            if(el.route === to.name){
                result = true
            }
        })
    }
    return result
}

export const _ = undefined
