<template>
    <b-row>
      <b-col cols="2">
        <p> {{ field.label }}</p>
      </b-col>
      <b-col cols="5">
        <b-form-select size="sm" :options="phoneOptions" :value="value.filter_sort_condition"
                       @input="updateModel($event, 'filter_sort_condition')" />
      </b-col>
      <b-col cols="5">
        <b-form-input :placeholder="field.label" size="sm" :value="value.filter_sort" @input="updateModel($event, 'filter_sort')" />
      </b-col>
    </b-row>
  </template>
  <script>
  export default {
    props: ['value', 'field'],
    name: 'sort',
    model: {
      prop: 'value',
      event: 'change'
    },
    data() {
      return {
        phoneOptions: [
          { value: 0, text: 'Условие', disabled: true },
          { value: 'equalOrMore', text: 'Больше или равно' },
          { value: 'equalOrLess', text: 'Меньше или равно' },
          { value: 'more', text: 'Больше' },
          { value: 'less', text: 'Меньше' },
          { value: 'startLike', text: 'Начинается с' },
          { value: 'endLike', text: 'Заканчивается на' },
          { value: 'include', text: 'Содержит' },
        ],
      }
    },
    methods: {
      updateModel(value, name) {
        if (name === 'filter_sort' || name === 'filter_sort_condition') {
          if (value === '') {
            value = null;
          }
          this.$emit('change', { val: value, field: name });
        }
  
        this.$store.state.filterPlus.filterPlus = null;
      },
    },
    mounted() {
      if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'sort' && this.$store.state.filterPlus.addFilterPlus) {
        const value = this.$store.state.filterPlus.filterPlus;
        const name = 'filter_sort';
        this.updateModel(value, name);
      }
    }
  }
  </script>