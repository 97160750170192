<template>
  <b-row>
    <b-col cols="2">
      <p> {{ field.label }}</p>
    </b-col>
    <b-col cols="5">
      <b-form-select size="sm" :options="phoneOptions" :value="value.filter_basis_condition"
                     @input="updateModel($event, 'filter_basis_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input :placeholder="field.label" size="sm" :value="value.filter_basis" @input="updateModel($event, 'filter_basis')" />
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: ['value', 'field'],
  name: 'sort_number',
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      phoneOptions: [
        { value: 0, text: 'Условие', disabled: true },
        { value: 'equalOrMore', text: 'Больше или равно' },
        { value: 'equalOrLess', text: 'Меньше или равно' },
        { value: 'more', text: 'Больше' },
        { value: 'less', text: 'Меньше' },
        { value: 'startLike', text: 'Начинается с' },
        { value: 'endLike', text: 'Заканчивается на' },
        { value: 'include', text: 'Содержит' },
      ],
    }
  },
  methods: {
    updateModel(value, name) {
      if (name === 'filter_basis' || name === 'filter_basis_condition') {
        if (value === '') {
          value = null;
        }
        this.$emit('change', { val: value, field: name });
      }

      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'basis' && this.$store.state.filterPlus.addFilterPlus) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = 'filter_basis';
      this.updateModel(value, name);
    }
  }
}
</script>