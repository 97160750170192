import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canGetAccess, canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import defaultProject from './routes/defaultProject'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'profile' } },
    ...defaultProject,
    {
      path: '*',
      redirect: 'error-404',
      redirect: 'error-403',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // Redirect to login if not logged in

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  if (!isLoggedIn && to.name !== 'auth-login') {
    if (to.name === 'auth-forgot-password') {
      return next()
    }else {
      return next({ name: 'auth-login' }) 
    }
  } else if (isLoggedIn && to.name == 'auth-login') {
    return next({ name: 'profile' })
  }else if (isLoggedIn && !canGetAccess(to) && to.name !== 'error-403') {
    return next({ name: 'error-403' })
  }

  // // If logged in => not authorized
  // return next({ name: 'misc-not-authorized' })

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
