<template>
    <b-row>
        <b-col cols="2">
            <p>Время разговора</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="conditionOptions" :value="value.filter_billsec_condition"
                @input="updateModel($event, 'filter_billsec_condition')"/>
        </b-col>
        <b-col cols="5">
            <b-input :value="value.filter_bill_sec"
                placeholder="Время разговора в секундах"
                @input="updateModel($event, 'filter_billsec')"
                size="sm"
             />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
	props: ['value'],
	name: 'billsec',
	model: {
		 prop: 'value',
		 event: 'change'
	},
	data() {
		 return {
			  conditionOptions: filterOptions
		 }
	},
	methods: {
		 updateModel(value, name) {
			  this.$emit('change', { val: value, field: name })
		 }
	},
	mounted() {
		 if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'billsec' && this.$store.state.filterPlus.addFilterPlus) {
			  const value = this.$store.state.filterPlus.filterPlus;
			  const name = 'filter_billsec';
			  this.updateModel(value, name);
		 }
	}
}
</script>