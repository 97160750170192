const filterNumberOptions = [
  { value: 0, text: 'Условие', disabled: true},
  { value: 'equalOrMore', text: 'Больше или равно' },
  { value: 'equalOrLess', text: 'Меньше или равно' },
  { value: 'more', text: 'Больше' },
  { value: 'less', text: 'Меньше' },
  { value: 'startLike', text: 'Начинается с' },
  { value: 'endLike', text: 'Заканчивается на' },
  { value: 'include', text: 'Содержит' },
]
export default filterNumberOptions;
