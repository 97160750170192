<template>
  <b-row>
      <b-col cols="2">
          <p>Статус</p>
      </b-col>
      <b-col cols="10">
          <b-form-select size="sm" :options="statuses" :value="value.filter_status_id"
              @input="updateModel($event, 'filter_status_id')" />
      </b-col>
  </b-row>
</template>
<script>

export default {
  props: ['value'],
  name: 'performer_photocontrolstatusname',
  model: {
      prop: 'value',
      event: 'change'
  },
  data() {
      return {
          statuses: [
            {value: 1, text: 'Назначен'},
            {value: 2, text: 'Принят'},
            {value: 3, text: 'Не принят'},
            {value: 4, text: 'Не проверен'},
          ]
      }
  },
  methods: {
      updateModel(value, name) {
          this.$emit('change', { val: value, field: name })
      }
  }
}
</script>