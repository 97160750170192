<template>
    <b-row>
        <b-col cols="2">
            <p>Тип адреса</p>
        </b-col>
        <b-col cols="10">
          <b-form-select
            size="sm"
            v-model="home_type_id"
            @input="updateModel($event, 'filter_home_type_id')"
          >
          <b-form-select-option disabled :value="undefined">Тип адреса</b-form-select-option>
                <b-form-select-option
                v-for="home_type in home_types"
                :key="'home_type-' + home_type.id"
                :value="home_type.id">
                    {{ home_type.name }}
                </b-form-select-option>
        </b-form-select>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: ['value'],
    name: 'home_type',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            home_types: [],
            home_type_id: undefined
        }
    },
    methods: {
        updateModel(value, name,) {
            this.$emit('change', { val: value, field: name, })
    },
    },
    mounted() {
        this.$http.get('addresses/home-types')
        .then(res => {
            this.home_types = res.data.home_types
        })
        .catch(err => {
        })
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'home_type' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_home_type';
            this.updateModel(value, name);
        }
    }
}
</script>