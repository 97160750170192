export default {
    state () {
        return {
            update: false,
            timer: null,    
        }
    },
    mutations:{},
    actions: {},
    getters: {},
  }