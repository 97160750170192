<template>
    <b-row>
      <b-col cols="2">
        <p>Cтатус клиента</p>
      </b-col>
      <b-col cols="10">
        <b-form-select
          size="sm"
          :options="typeOptions"
          :value="filterValue"
          @input="updateModel($event, 'filter_client_status')"
        />
      </b-col>
    </b-row>
  </template>
  
  <script>
  export default {
    props: ['value'],
    name: 'client_status',
    model: {
      prop: 'value',
      event: 'change'
    },
    data() {
      return {
        typeOptions: [
          { value: 0, text: 'Не аторизован' },
          { value: 1, text: 'Авторизован' },
        ],
        filterValue: this.value.filter_client_status
      };
    },
    mounted() {
      this.checkFilterPlus();
    },
    watch: {
      '$store.state.filterPlus.filterPlus'(newValue) {
        if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'client_status' && this.$store.state.filterPlus.addFilterPlus) {
          this.checkFilterPlus();
        }
      }
    },
    methods: {
      checkFilterPlus() {
        const filterPlus = this.$store.state.filterPlus;
        if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'client_status' && this.$store.state.filterPlus.addFilterPlus) {
          const value = filterPlus.filterPlus;
          const name = 'filter_client_status';
  
          // Находим соответствующий объект типа, основываясь на тексте
          const selectedType = this.typeOptions.find(type => type.text === value);
  
          if (selectedType) {
            this.filterValue = selectedType.value;
            this.updateModel(selectedType.value, name);
          }
        }
      },
      updateModel(value, name) {
        this.filterValue = value;
        this.$emit('change', { val: value, field: name });
      }
    }
  };
  </script>
  