<template>
	<b-row>
	  <b-col cols="2">
		 <p>Телефон</p>
	  </b-col>
	  <b-col cols="5">
		 <b-form-select
			size="sm"
			:options="phoneOptions"
			:value="value.filter_phone_condition"
			@input="updateModel($event, 'filter_phone_condition')"
		 />
	  </b-col>
	  <b-col cols="5">
		 <b-form-input
			placeholder="Телефон"
			size="sm"
			:value="value.filter_phone"
			@input="updateModel($event, 'filter_phone')"
		 />
	  </b-col>
	</b-row>
 </template>
 
 <script>
 import filterOptions from '@/utils/constants/filerConstants/parametrs';
 export default {
	props: ['value'],
	name: 'phone',
	model: {
	  prop: 'value',
	  event: 'change'
	},
	data() {
	  return {
		 phoneOptions: filterOptions
	  }
	},
	methods: {
	  updateModel(value, name) {
		 if (name === 'filter_phone' || name === 'filter_phone_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
	  },
	},
	mounted() {
	  if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'phone' && this.$store.state.filterPlus.addFilterPlus) {
		 const value = this.$store.state.filterPlus.filterPlus;
		 const name = 'filter_phone';
		 this.updateModel(value, name);
	  }
	}
 }
 </script>
 