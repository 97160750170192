const filterOptions = [
  { value: 0, text: 'Условие', disabled: true},
  { value: 'startLike', text: 'Начинается с' },
  { value: 'endLike', text: 'Заканчивается на' },
  { value: 'include', text: 'Содержит' },
  { value: 'not_include', text: 'Не содержит' },
  { value: 'nullable', text: 'Равно пустому' },
  { value: 'notNullable', text: 'Не равно пустому' },
  { value: 'inArray', text: 'Из перечня' },
  { value: 'notInArray', text: 'Вне перечня' }
]
export default filterOptions;
