<template>
    <b-row>
        <b-col cols="2">
            <p>Статус звонка</p>
        </b-col>
        <b-col cols="10">
                <b-form-select size="sm" :value="value.filter_disposition"
                @input="updateModel($event, 'filter_disposition')" >
                <option value="ANSWERED">Принят</option>
                <option value="NO ANSWER">Не принят</option>
                <option value="BUSY">Не дозвонился</option>
                <option value="FAILED">Ошибка</option>
                <option value="CONGESTION">Соеденение прервано</option>
            </b-form-select>
        </b-col>
    </b-row>
</template>
<script>

export default {
    props: ['value'],
    name: 'disposition',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            // dispositions: []
        }
    },
    methods: {
        checkFilterPlus() {
			  const filterPlus = this.$store.state.filterPlus;
			  if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'disposition' && this.$store.state.filterPlus.addFilterPlus) {
				 const value = filterPlus.filterPlus;
				 const name = 'filter_disposition';
	  
				 const selectedType = this.Options.find(status_auto => status_auto.text === value);
	  
				 if (selectedType) {
					this.filterValue = selectedType.value;
					this.updateModel(selectedType.value, name);
				 }
			  }
			},
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    },
    mounted(){
        // this.$http
        // .get('asterisk-api/statuses')
        // .then(res => {
        //      for(let i of res.data){
        //         this.dispositions.push({
        //             value: i.id,
        //             text: i.disposition
        //         })
        //     }
        // })
        this.checkFilterPlus();
    },
    watch: {
            '$store.state.filterPlus.filterPlus'(newValue) {
                if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'status_auto' && this.$store.state.filterPlus.addFilterPlus) {
                this.checkFilterPlus();
            }
        }
    }
}
</script>