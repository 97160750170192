<template>
    <div>
        <b-row class="mt-1">
            <b-col cols="2">
                <p>{{ field.label }}</p>
            </b-col>
            <b-col cols="10">
                <b-form-select size="sm" :options="this.$route.name == 'car-state/current' ? statusOption : statusOptions" :value="value.filter_is_active"
                    @input="updateModel($event, filterName('filter_is_active'))" />
            </b-col>
        </b-row>
    </div>
    </template>
    <script>
    export default {
        props: ['value', 'field'],
        name: 'performer_is_active',
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                statusOptions: [
                    { value: false, text: 'Занят',},
                    { value: true, text: 'Свободен' },
                ],
                statusOption: [
                    { value: 0, text: 'Занят',},
                    { value: 1, text: 'Свободен' },
                ],
                currentRoute: this.$route.fullPath,
            }
        },
        methods: {
            updateModel(value, name) {
                this.$emit('change', { val: value, field: name })
            },
            filterName(){
                if (this.$route.name == 'car-state/current') {
                    return 'filter_is_active'
                } else {
                    return 'filter_status_id'
                }
            }
        },
        watch: {
            $route(to, from) {
                this.currentRoute = to.fullPath;
            },
        }
    }
    </script>