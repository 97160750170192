let Tabs = JSON.parse(localStorage.getItem('tabs'))
let allTabs = Tabs
let defaultTab = [
  {
    id: 1,
    name: 'Главная',
    path: '/',
    query: undefined
  }
]
if (Tabs == null || Tabs.length < 1) {
  Tabs = []
  allTabs = Tabs.concat(defaultTab)
}
let currTabId = localStorage.getItem('currentTabId')
if (currTabId == null || Tabs == null || Tabs.length < 1) {
  currTabId = 1
}
let nextTab = localStorage.getItem('nextTabId')
if (nextTab == null || Tabs == null || Tabs.length < 1) {
  nextTab = 2
}
export default {
  namespaced: true,
  state: {
    tabs: allTabs,
    currentTabId: currTabId,
    nextTabId: nextTab,
  },
  getters: {
  },
  mutations: {
    CLEAR_TAB(state) {
      state.tabs = [{
        id: 1,
        name: 'Главная',
        path: '/',
        query: undefined
      }]
    },
    ADD_TAB(state, val) {
      let isTrue = false
      state.tabs.forEach((el) => {
        if (el.name == val[0]) {
          isTrue = false
        } else {
          isTrue = true
        }
      })
      if (isTrue) {
        state.tabs.push({
          id: state.nextTabId,
          name: val[0],
          path: val[1],
          query: val[2]
        })
        state.currentTabId = state.nextTabId;
        state.nextTabId++
        localStorage.setItem('tabs', JSON.stringify(state.tabs))
        localStorage.setItem('currentTabId', state.currentTabId)
        localStorage.setItem('nextTabId', state.nextTabId)
      }

    },
    CLOSE_TAB(state, val){
        state.tabs.forEach((tab, index) => {
          if (tab.id == val) {
            state.tabs.splice(index, 1)
            state.currentTabId = state.tabs[index] ? state.tabs[index].id : state.tabs[index - 1].id
            localStorage.setItem('tabs', JSON.stringify(state.tabs))
            localStorage.setItem('currentTabId', state.currentTabId)
          }
        });
    },
    CHANGE_TAB(state, val){
        state.currentTabId = val;
        localStorage.setItem('currentTabId', state.currentTabId)
    }

  },
  actions: {},
}
