<template>
    <b-row>
        <b-col cols="2">
            <p>Категория</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="Options" :value="value.filter_fast_address_category_condition"
                @input="updateModel($event, 'filter_fast_address_category_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Категория" size="sm" :value="value.filter_fast_address_category" @input="updateModel($event, 'filter_fast_address_category')" />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
    props: ['value'],
    name: 'fast_address_category',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            Options: filterOptions
        }
    },
    methods: {
		updateModel(value, name) {
		 if (name === 'filter_fast_address_category' || name === 'filter_fast_address_category_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
	
	  },
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'category.name' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_fast_address_category';
            this.updateModel(value, name);
        }
    }
}
</script>