<template>
  <b-row style="margin-bottom: 10px;">
    <b-col cols="2">
      <p>Цвет</p>
    </b-col>
    <b-col cols="10">
        <v-select :value="value.filter_color_id"  :reduce="options => options.value"
          label="text"
          :options="car_color"
          @input="updateModel($event, 'filter_color_id')"/>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
        vSelect
  },
  props: ['value'],
  name: 'car_color',
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      car_color: [],
		filterValue: this.value.filter_color_id
    };
  },
  methods: {
    fetchCarModel() {
      this.$http
        .get('car-settings/car-colors')
        .then(res => {
          this.car_color = res.data.map(color => ({
            value: color.id,
            text: color.name
          }));
          this.updateFilterValue();
        })
        .catch(error => {});
    },
    updateModel(value, name) {
      this.filterValue = value;
      this.$emit('change', { val: value, field: name });
    },
    updateFilterValue() {
      const filterPlus = this.$store.state.filterPlus;
      if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'color' && this.$store.state.filterPlus.addFilterPlus) {
        const selectedModel = this.car_color.find(color => color.text === filterPlus.filterPlus);
        if (selectedModel) {
          this.filterValue = selectedModel.value;
          this.$emit('change', { val: selectedModel.value, field: 'filter_color_id' });
        }
      }
    }
  },
  mounted() {
    this.fetchCarModel();
  },
  watch: {
    '$store.state.filterPlus.filterPlus'(newValue) {
      if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'model' && this.$store.state.filterPlus.addFilterPlus) {
        this.updateFilterValue();
      }
    }
  }
};
</script>

<style scoped>
</style>
