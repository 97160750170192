export default {
  namespaced: true,
  state:{
      dataCount: null
  },
  mutations:{
      setdataCount(state, dataCount) {
          state.dataCount = dataCount
      },
  },
  actions: {},
  getters: {},
}