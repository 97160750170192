<template>
    <b-row>
        <b-col cols="2">
            <p>Категория</p>
        </b-col>
        <b-col cols="10">
            <b-form-select size="sm" :options="transaction_category" :value="value.filter_category"
                @input="updateModel($event, 'filter_category')" />
        </b-col>
    </b-row>
</template>
<script>

export default {
    props: ['value'],
    name: 'transaction_category',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            transaction_category: [
                {
                    text: 'Пополнение',
                    value: 'payment'
                },
                {
                    text: 'Списание',
                    value: 'charge'
                }
            ]

        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    }
}
</script>