<template>
    <div v-if="shouldRenderFilter">
      <b-row>
        <b-col cols="2">
          <p>Статусы</p>
        </b-col>
        <b-col cols="10">
          <b-form-select
            size="sm"
            :options="currentRoute === '/order/index' || currentRoute === '/orderJournals/index' ? statusOptions : statusOption"
            :value="filterValue"
            @input="updateModel($event, 'filter_status_id')"
          />
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  export default {
    props: ['value'],
    name: 'status',
    model: {
      prop: 'value',
      event: 'change'
    },
    data() {
      return {
        statusOptions: [],
		  statusOption: [
          { value: 1, text: 'На доработку' },
          { value: 2, text: 'Водитель приглашен' },
          { value: 3, text: 'Не дозвонились' },
          { value: 4, text: 'Водитель принят' },
          { value: 5, text: 'Отказать' },
          { value: 6, text: 'Не дошел' },
        ],

        currentRoute: this.$route.fullPath,
        filterValue: this.value.filter_status_id
      };
    },
    computed: {
      shouldRenderFilter() {
        const allowedRoutes = ['/order/index', '/taxi/forms/index', '/orderJournals/index'];
        return allowedRoutes.includes(this.currentRoute);
      },
    },
    watch: {
      $route(to, from) {
        this.currentRoute = to.fullPath;
		  this.fetchData()
      },
      '$store.state.filterPlus.filterPlus'(newValue) {
        if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'status' && this.$store.state.filterPlus.addFilterPlus) {
          this.updateFilterValue();
        }
      },
    },
    mounted() {
		 this.fetchData()
      if (
        this.$store.state.filterPlus.filterPlus !== null &&
        this.$store.state.filterPlus.keyFilter === 'status' && 
        this.$store.state.filterPlus.addFilterPlus &&
        this.shouldRenderFilter
      ) {
			
        this.updateFilterValue();
      }
    },
    methods: {

		 
		 fetchData() {
			 this.$http
			 .get('orders/data')
			 .then(response => {
				this.updateStatusOptions(response.data.order_statuses)
        this.updateFilterValue();
			}) 
			.catch(error => {
			});
			
		},
		updateStatusOptions(items){
			this.statusOptions = items.map(option => {
				return{
					value: option.id,
					text: option.name
				}
			})
		},
      updateFilterValue() {
        const filterPlus = this.$store.state.filterPlus;
        if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'status' && filterPlus.addFilterPlus) {
          const selectedStatus = this.statusOptions.find(option => option.text === filterPlus.filterPlus);
          if (selectedStatus) {
            this.filterValue = selectedStatus.value;
          }
        }
      },
      updateModel(value, name) {
        this.filterValue = value;
        this.$emit('change', { val: value, field: name });
      },
    },
  };
  </script>
  