import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'
import '../node_modules/remixicon/fonts/remixicon.css'

// Global Components
import './global-components'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueHotkey from 'v-hotkey'
import moment from 'moment'

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker)


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueHotkey)



// 3rd party plugins
import '@axios'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/vue-select'


Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueCompositionAPI)

require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$moment = moment

// event bus
export const eventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
