<template>
  <b-row>
    <b-col cols="2">
      <p>Коммиссия</p>
    </b-col>
    <b-col cols="5">
      <b-form-select
        size="sm"
        :options="commissionOptions"
        :value="value.filter_commission_price_condition"
        @input="updateModel($event, 'filter_commission_price_condition')"
      />
    </b-col>
    <b-col cols="5">
      <b-form-input
        placeholder="Коммиссия"
        size="sm"
        :value="value.filter_commission_price"
        @update="updateModel($event, 'filter_commission_price')"
      />
    </b-col>
  </b-row>
</template>

<script>
import filterNumberOptions from "@/utils/constants/filerConstants/numberParametrs";
export default {
  props: ["value"],
  name: "commission",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      commissionOptions: filterNumberOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      if (
        name === "filter_commission_price" ||
        name === "filter_commission_price_condition"
      ) {
        if (value === "") {
          value = null;
        }
        this.$emit("change", { val: value, field: name });
      }
      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "commission_price" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_commission_price";
      this.updateModel(value, name);
    }
  },
  watch: {
    $route(to, from) {
      if (from.fullPath !== to.fullPath) {
        this.$this.$store.state.filterPlus = null;
      }
    },
  },
};
</script>
