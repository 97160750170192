<template>
    <b-row>
        <b-col cols="2">
            <p>Тариф</p>
        </b-col>
        <b-col cols="10">
            <b-form-select size="sm" :options="tariffOptions" :value="filterValue"
                @input="updateModel($event, 'filter_tariff_id')" />
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: ['value'],
    name: 'tariff',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            tariffOptions: [
                { value: 0, text: 'Тариф', disabled: true},
                { value: 1, text: 'Эконом' },
                { value: 2, text: 'Бизнес' },
                { value: 3, text: 'Купим и привезем' },
            ],
            filterValue: this.value.filter_order_type_id
        }
    },
    mounted() {
      this.checkFilterPlus();
    },
    watch: {
      '$store.state.filterPlus.filterPlus'(newValue) {
        if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'tariff' && this.$store.state.filterPlus.addFilterPlus) {
          this.checkFilterPlus();
        }
      }
    },
    methods: {
        checkFilterPlus() {
        const filterPlus = this.$store.state.filterPlus;
        if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'tariff' && this.$store.state.filterPlus.addFilterPlus) {
          const value = filterPlus.filterPlus;
          const name = 'filter_tariff_id';
  
          const selectedType = this.tariffOptions.find(tariff => tariff.text === value);
  
          if (selectedType) {
            this.filterValue = selectedType.value;
            this.updateModel(selectedType.value, name);
          }
        }
      },
        updateModel(value, name) {
            this.filterValue = value;
            this.$emit('change', { val: value, field: name })
        }
    }
}
</script>