<template>
  <b-row class="mt-1">
    <b-col cols="2">
      <p>Бренд</p>
    </b-col>
    <b-col cols="10">
      <v-select
        :value="value.filter_car_brand_id"
        :reduce="(options) => options.id"
        label="name"
        :options="car_brands"
        @input="updateModel($event, 'filter_car_brand_id')"
      />
    </b-col>
  </b-row>
</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["value"],
  name: "carBrand",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      car_brands: [],
    };
  },
  methods: {
    updateModel(value, name) {
      this.$emit("change", { val: value, field: name });
    },
  },
  mounted() {
    this.$http.get("car-settings/brands?filter_is_active=1").then((res) => {
      this.car_brands = res.data;
    });
  },
};
</script>
