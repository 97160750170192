<template>
  <b-row>
    <b-col cols="2">
      <p>Код</p>
    </b-col>
    <b-col cols="5">
      <b-form-select size="sm" :options="commissionOptions" :value="value.filter_code_type_doc_condition"
                     @input="updateModel($event, 'filter_code_type_doc_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input placeholder="Код" size="sm" :value="value.filter_code_type_doc" @update="updateModel($event, 'filter_code_type_doc')" />
    </b-col>
  </b-row>
</template>

<script>
import filterNumberOptions from '@/utils/constants/filerConstants/numberParametrs';
export default {
  props: ['value'],
  name: 'code_type_doc',
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      commissionOptions: filterNumberOptions
    }
  },
  methods: {
    updateModel(value, name) {
      if (name === 'filter_code_type_doc' || name === 'filter_code_type_doc_condition') {
        if (value === '') {
          value = null;
        }
        this.$emit('change', { val: value, field: name });
      }
      this.$store.state.filterPlus.filterPlus = null;
    }
  },
  mounted() {
    if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'code_type_doc' && this.$store.state.filterPlus.addFilterPlus) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = 'filter_code_type_doc';
      this.updateModel(value, name);
    }
  },
  watch: {
    $route(to, from) {
      if(from.fullPath !== to.fullPath) {
        this.$this.$store.state.filterPlus = null
      }
    }
  }
}
</script>