<template>
    <b-row>
        <b-col cols="2">
        </b-col>
        <b-col style="margin-bottom: 5px" cols="10">
            <b-form-select size="sm" v-model="divisionId"
                @input="updateModel($event, 'filter_order_division_id' )">
                <b-form-select-option disabled :value="undefined">Подразделение</b-form-select-option>
                <b-form-select-option
                v-for="division in divisions"
                :key="'division-' + division.id"
                :value="division.id">
                    {{ division.name }}
                </b-form-select-option>
            </b-form-select>
        </b-col>
    </b-row>
</template>
<script>

export default {
    props: ['value'],
    name: 'division_order_id',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            divisions:[],
            divisionId: undefined
        }
    },
    mounted() {
  this.divisionId = this.value?.division_id;
  this.$http.get('divisions?disabled=1')
    .then(res => {
      this.divisions = res.data;
      this.checkFilterPlus();
    });
},
watch: {
  '$store.state.filterPlus.filterPlus'(newValue) {
    if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'order_division_id' && this.$store.state.filterPlus.addFilterPlus) {
      this.checkFilterPlus();
    }
  }
},
methods: {
  checkFilterPlus() {
    const filterPlus = this.$store.state.filterPlus;
    if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'order_division_id' && this.$store.state.filterPlus.addFilterPlus) {
      const value = filterPlus.filterPlus;
      const name = 'filter_order_division_id';

      // Находим соответствующий объект division, основываясь на имени
      const selectedDivision = this.divisions.find(division => division.name === value);

      if (selectedDivision) {
        this.divisionId = selectedDivision.id;
        this.updateModel(selectedDivision.id, name);
      }
    }
  },
    updateModel(value, name,) {
            this.$emit('change', { val: value, field: name, })
    },
  }
}
</script>