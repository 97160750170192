<template>
  <b-modal id="ChangePasswordModal" ref="ChangePasswordModal" title="Изменение пароля" size="md">
    <b-row class="mb-1">
      <b-col class="12">
        Старый пароль
      </b-col>
        <b-col class="12">
          <b-input-group
              class="form-control-merge"
          >
          <b-form-input 
            :type="isClicked? 'password' : 'text'"       
            class="form-control-merge"
            v-model.trim="passConfig.old"
          />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="showIcon1"
                @click="clicked1()"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col class="12">
        Новый пароль
      </b-col>
        <b-col class="12">
          <b-input-group
              class="form-control-merge"
          >
          <b-form-input 
            :type="click? 'password' : 'text'"       
            class="form-control-merge"
            v-model.trim="passConfig.new"
          />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="showIcon2"
                @click="clicked2()"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>      
    </b-row>

    <b-row class="mb-1">
      <b-col class="12">
        Новый пароль повторно
      </b-col>
        <b-col class="12">
          <b-input-group
              class="form-control-merge"
          >
          <b-form-input 
            :type="Click? 'password' : 'text'"       
            class="form-control-merge"
            v-model.trim="passConfig.confirm"
          />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="showIcon3"
                @click="clicked3()"
              />
            </b-input-group-append>
          </b-input-group>     
        </b-col>      
    </b-row>
      <template #modal-footer>
        <button 
        @click="savePasswordConfig" 
        class="btn btn-primary"
        :disabled="isSaveButtonDisabled"
        >Сохранить
        </button>

        <b-button
        @click="closeModal">
          Отмена
        </b-button>
      </template>
  </b-modal>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' 


export default {
  components:{
    ToastificationContent
  },
  data(){
    return {
      passConfig:{
        old:'',
        new:'',
        confirm:'',
      },
        isClicked: false,
        click: false,
        Click: false
    }
  },
  computed: {
    showIcon1() {
      return this.isClicked ? 'EyeIcon' : 'EyeOffIcon' 
    },
    showIcon2() {
      return this.click ? 'EyeIcon' : 'EyeOffIcon' 
    },
    showIcon3() {
      return this.Click ? 'EyeIcon' : 'EyeOffIcon' 
    },
    isSaveButtonDisabled() {
      return (
        (this.passConfig.old === null || this.passConfig.old === undefined || this.passConfig.old.trim() === '') ||
        (this.passConfig.new === null || this.passConfig.new === undefined || this.passConfig.new.trim() === '') ||
        (this.passConfig.confirm === null || this.passConfig.confirm === undefined || this.passConfig.confirm.trim() === '')
      ) ? true : false;
    }
  },
  methods :{
    clicked1() {
      this.isClicked = !this.isClicked
    },
    clicked2() {
      this.click = !this.click
    },
    clicked3() {
      this.Click = !this.Click
    },
    savePasswordConfig(){
      this.$http
      .post(`/user/reset-password?password=${this.passConfig.old}&new_password=${this.passConfig.new}&new_password_confirmation=${this.passConfig.confirm}`)
      .then(res =>{
        this.submitButtonDisabled = true
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
              title: 'Успех!',
              icon: 'CheckIcon',
              variant: 'success',
              text: `Пароль изменен`,
          },
        })
        this.$refs['ChangePasswordModal'].hide()
      .catch(err => {
        this.submitButtonDisabled = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
              title: 'Ошибка!',
              icon: 'XIcon',
              variant: 'danger',
              text: `Некоректные данные`,
          },
        })
      })        
        .finally(()=>{
            this.submitButtonDisabled = false
        })
      })
    },
    closeModal(){
      this.passConfig.old = '';
      this.passConfig.new = '';
      this.passConfig.confirm = '';
      this.$refs['ChangePasswordModal'].hide();
    }
  }
}
</script>
