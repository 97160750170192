<template>
<div>
    <b-row>
        <b-col cols="2">
            <p>Пол</p>
        </b-col>
        <b-col cols="10">
            <b-form-select size="sm" :options="Options" :value="filterValue"
                @input="updateModel($event, 'filter_gender')" />
        </b-col>
    </b-row>
</div>
</template>
<script>
export default {
    props: ['value'],
    name: 'status',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            Options: [
                { value: 0, text: 'Женский'},
                { value: 1, text: 'Мужской' },

            ],
            currentRoute: this.$route.fullPath,
            filterValue: this.value.filter_gender
        }
    },
    methods: {
        checkFilterPlus() {
        const filterPlus = this.$store.state.filterPlus;
        if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'gender' && this.$store.state.filterPlus.addFilterPlus) {
          const value = filterPlus.filterPlus;
          const name = 'filter_gender';
  
          const selectedType = this.Options.find(gender => gender.text === value);
  
          if (selectedType) {
            this.filterValue = selectedType.value;
            this.updateModel(selectedType.value, name);
          }
        }
      },
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        },
    },
    mounted() {
      this.checkFilterPlus();
    },
    watch: {
        '$store.state.filterPlus.filterPlus'(newValue) {
            if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'gender') {
            this.checkFilterPlus();
        }
      }
    }
}
</script>