<template>
    <b-row>
        <b-col cols="2">
            <p>{{field.label}}</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="options" :value="value.filter_lng_condition"
                @input="updateModel($event, 'filter_lng_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Долгота" size="sm" :value="value.filter_lng" @update="updateModel($event, 'filter_lng')" />
        </b-col>
    </b-row>
</template>
<script>
import filterNumberOptions from "@/utils/constants/filerConstants/numberParametrs";
export default {
    props: ['value', 'field'],
    name: 'lgt',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            options: filterNumberOptions
        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'lng' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_lng';
            this.updateModel(value, name);
        }
    }
}
</script>