<template>
    <b-row>
        <b-col cols="2">
            <p>{{field.label}}</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="Options" :value="value.filter_client_hint_condition"
                @input="updateModel($event, 'filter_client_hint_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Введите данные" size="sm" :value="value.filter_client_hint" @update="updateModel($event, 'filter_client_hint')" />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
    props: ['value', 'field'],
    name: 'clientHint',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            Options: filterOptions
        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'client_hint' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_client_hint';
            this.updateModel(value, name);
        }
    }
}
</script>