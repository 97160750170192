<template>
    <b-row class="mb-1">
        <b-col cols="2">
            <p>{{ field.label }}</p>
        </b-col>
        <b-col cols="5">
            <label for="" style="display:block; text-align: right;">От</label>
            <date-picker
                :value="value.filter_from_calldate ? value.filter_from_calldate.replaceAll('T', ' ') :''"
                @input="updateModel($event, 'filter_from_calldate')"
                type="datetime"
                class="w-100"
                v-mask="'##:## ##-##-####'"
                format="HH:mm DD-MM-YYYY"
                :value-type="'DD-MM-YYYY HH:mm'"
                placeholder="HH:mm DD-MM-YYYY"
            >
            </date-picker>
        </b-col>
        <b-col cols="5">
            <label for="" style="display:block; text-align: right;">До</label>
            <date-picker
                :value="value.filter_to_calldate ? value.filter_to_calldate.replaceAll('T', ' ') :''"
                @input="updateModel($event, 'filter_to_calldate')"
                type="datetime"
                class="w-100"
                v-mask="'##:## ##-##-####'"
                format="HH:mm DD-MM-YYYY"
                :value-type="'DD-MM-YYYY HH:mm'"
                placeholder="HH:mm DD-MM-YYYY"
            >
            </date-picker>
        </b-col>
    </b-row>
</template>
<script>
import { mask } from "vue-the-mask"
export default {
    directives: { mask },
    props: ['value','field'],
    name: 'calldate',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
        }
    },
    methods: {
        updateModel(value, name) {
            let date = { field: name };
            if (value && typeof value === 'string') {
            date.val = value.replaceAll('T', ' ');
            } else {
            date.val = ''; 
            }
            this.$emit('change', date);
        },

    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'calldate' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_from_calldate';
            this.updateModel(value, name);
        }
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'calldate' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_to_calldate';
            this.updateModel(value, name);
        }
    }
}
</script>
<style>
.mx-calendar-content td, .mx-calendar-content th {
    width: auto !important;
    text-align: center !important;
    /* min-width: fit-content !important */
}
</style>