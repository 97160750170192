<template>
    <b-row>
        <b-col cols="2">
            <p>Улица</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="streetOptions" :value="value.filter_street_name_condition"
                @input="updateModel($event, 'filter_street_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Улица" size="sm" :value="value.filter_street_name" @input="updateModel($event, 'filter_street')" />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
    props: ['value'],
    name: 'street',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            streetOptions: filterOptions
        }
    },
    methods: {
		updateModel(value, name) {
		 if (name === 'filter_street_name' || name === 'filter_street_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
	  },
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'street' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_street';
            this.updateModel(value, name);
        }
    }
}
</script>