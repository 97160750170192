<template>
  <div>
    <b-row>
      <b-col cols="2">
        <p>{{ field.label }}</p>
      </b-col>
      <b-col cols="10">
        <b-form-select size="sm" :options="is_blockOptions" :value="filterValue"
                       @input="updateModel($event, 'filter_blocked')" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ['value', 'field'],
  name: 'status',
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      is_blockOptions: [
        { value: 0, text: 'Разблокирован'},
        { value: 1, text: 'Блокирован' },

      ],
      currentRoute: this.$route.fullPath,
      filterValue: this.value.filter_blocked
    }
  },
  methods: {
    checkFilterPlus() {
      const filterPlus = this.$store.state.filterPlus;
      if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'is_block' && this.$store.state.filterPlus.addFilterPlus) {
        const value = filterPlus.filterPlus;
        const name = 'filter_is_active';

        const selectedType = this.Options.find(is_block => is_block.text === value);

        if (selectedType) {
          this.filterValue = selectedType.value;
          this.updateModel(selectedType.value, name);
        }
      }
    },
    updateModel(value, name) {
      this.$emit('change', { val: value, field: name })
    },
  },
  mounted() {
    this.checkFilterPlus();
  },
  watch: {
    '$store.state.filterPlus.filterPlus'(newValue) {
      if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'blocked' && this.$store.state.filterPlus.addFilterPlus) {
        this.checkFilterPlus();
      }
    }
  }
}
</script>