<template>
    <b-row>
        <b-col cols="2">
            <p>Тип улицы</p>
        </b-col>
        <b-col cols="10">
          <b-form-select
            size="sm"
            v-model="street_type"
            @input="updateModel($event, 'filter_street_type_id')"
          >
          <b-form-select-option disabled :value="undefined">Тип улицы</b-form-select-option>
                <b-form-select-option
                v-for="type in street_types"
                :key="'type-' + type.id"
                :value="type.id">
                    {{ type.name }}
                </b-form-select-option>
        </b-form-select>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: ['value'],
    name: 'street_type',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            street_type: undefined,
            street_types: []
        }
    },
    methods: {
        updateModel(value, name,) {
            this.$emit('change', { val: value, field: name, })
    },
    },
    mounted() {
        this.$http.get('addresses/street-type')
        .then(res => {
            this.street_types = res.data
        })
        .catch(err => {
        })
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'street_type' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_street_type_id';
            this.updateModel(value, name);
        }
    }
}
</script>