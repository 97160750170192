<template>
    <b-row>
        <b-col cols="2">
            <p>Дистанция</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="distanceOptions" :value="value.filter_performer_distance_condition"
                @input="updateModel($event, 'filter_performer_distance_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Дистанция" size="sm" :value="value.filter_performer_distance" @update="updateModel($event, 'filter_performer_distance')" />
        </b-col>
    </b-row>
</template>

<script>
import filterNumberOptions from "@/utils/constants/filerConstants/numberParametrs";
export default {
    props: ['value'],
    name: 'performer_distance',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            distanceOptions: filterNumberOptions
        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'distance' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_performer_distance';
            this.updateModel(value, name);
        }
    }
}
</script>