<template>
    <b-row>
        <b-col cols="2">
            <p>{{field.label}}</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="firstNameOptions" :value="value.filter_performer_full_name_condition"
                @input="updateModel($event, 'filter_performer_full_name_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Ф.И.О" size="sm" :value="value.filter_performer_full_name" @update="updateModel($event, 'filter_performer_full_name')" />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
    props: ['value', 'field'],
    name: 'last_name',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            firstNameOptions: filterOptions
        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'performer_first_name' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_performer_full_name';
            this.updateModel(value, name);
        }
    }
}
</script>