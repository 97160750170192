<template>
  <b-row>
    <b-col cols="2">
      <p>{{field.label}}</p>
    </b-col>
    <b-col cols="5">
      <b-form-select size="sm" :options="Options" :value="value.filter_type_tariff_performer_name_condition"
                     @input="updateModel($event, 'filter_type_tariff_performer_name_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input placeholder="Введите данные" size="sm" :value="value.filter_type_tariff_performer_name" @input="updateModel($event, 'filter_type_tariff_performer_name')" />
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: ['value', 'field'],
  name: 'type_tariff_performer',
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      Options: [
        { value: 0, text: 'Условие', disabled: true},
        { value: 'startLike', text: 'Начинается с' },
        { value: 'endLike', text: 'Заканчивается на' },
        { value: 'include', text: 'Содержит' },
        { value: 'not_include', text: 'Не содержит' },
      ],
    }
  },
  methods: {
    updateModel(value, name) {
      if (name === 'filter_type_tariff_performer_name' || name === 'filter_type_tariff_performer_name_condition') {
        if (value === '') {
          value = null;
        }
        this.$emit('change', { val: value, field: name });
      }

      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'type_tariff_performer' && this.$store.state.filterPlus.addFilterPlus) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = 'filter_type_tariff_performer_name';
      this.updateModel(value, name);
    }
  }
}
</script>