<template>
    <b-row>
        <b-col cols="2">
            <p>{{ field.label }}</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="streetOptions" :value="value.filter_zone_condition"
                @input="updateModel($event, 'filter_zone_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input :placeholder="field.label" size="sm" :value="value.filter_zone" @update="updateModel($event, 'filter_zone')" />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
    props: ['value', 'field'],
    name: 'zone_state',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            streetOptions: filterOptions
        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'zone' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_zone';
            this.updateModel(value, name);
        }
    }
}
</script>