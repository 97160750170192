<template>
  <b-row>
    <b-col cols="2">
      <p>Состояние</p>
    </b-col>
    <b-col cols="10">
      <b-form-select
        size="sm"
        :options="car_conditions"
        :value="filterValue"
        @input="updateModel($event, 'filter_car_condition_id')"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['value'],
  name: 'condition',
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      car_conditions: [],
      filterValue: this.value.filter_car_condition_id
    };
  },
  methods: {
    fetchCarConditions() {
      this.$http
        .get('directory/cars')
        .then(res => {
          this.car_conditions = res.data.car_conditions.map(condition => ({
            value: condition.id,
            text: condition.name
          }));
          this.updateFilterValue();
        })
        .catch(error => {});
    },
    updateModel(value, name) {
      this.filterValue = value;
      this.$emit('change', { val: value, field: name });
    },
    updateFilterValue() {
      const filterPlus = this.$store.state.filterPlus;
      if (filterPlus.filterPlus !== null && filterPlus.keyFilter === 'condition' && this.$store.state.filterPlus.addFilterPlus) {
        const selectedCondition = this.car_conditions.find(condition => condition.text === filterPlus.filterPlus);
        if (selectedCondition) {
          this.filterValue = selectedCondition.value;
          this.$emit('change', { val: selectedCondition.value, field: 'filter_car_condition_id' });
        }
      }
    }
  },
  mounted() {
    this.fetchCarConditions();
  },
  watch: {
    '$store.state.filterPlus.filterPlus'(newValue) {
      if (newValue !== null && this.$store.state.filterPlus.keyFilter === 'condition') {
        this.updateFilterValue();
      }
    },
  }
};
</script>
