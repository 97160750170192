<template>
    <b-row>
        <b-col cols="2">
            <p>Вод. уд.</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="Options" :value="value.filter_serials_number_condition"
                @input="updateModel($event, 'filter_serials_number_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Водительское удостоверение" size="sm" :value="value.filter_serials_number" @input="updateModel($event, 'filter_serials_number')" />
        </b-col>
    </b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
    props: ['value'],
    name: 'serialsNumber',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            Options: filterOptions
        }
    },
    methods: {
		updateModel(value, name) {
		 if (name === 'filter_serials_number' || name === 'filter_serials_number_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
	  },
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'serials_number' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_serials_number';
            this.updateModel(value, name);
        }
    }
}
</script>