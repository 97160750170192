export default {
  state:{
      fetchingNewData: false,
      waiting_filter: false
  },
  mutations:{
    REFRESH_DATA(state, val) {
      state.fetchingNewData = val
    },
    LOADER_FILTER(state, val) {
      state.waiting_filter = val
    },
  },
  actions: {},
  getters: {},
}