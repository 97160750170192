<template>
    <b-row>
        <b-col cols="2">
            <p>{{field.label}}</p>
        </b-col>
        <b-col cols="5">
            <b-form-select size="sm" :options="Options" :value="value.filter_code_condition"
                @input="updateModel($event, 'filter_code_condition')" />
        </b-col>
        <b-col cols="5">
            <b-form-input placeholder="Введите данные" size="sm" :value="value.filter_code" @input="updateModel($event, 'filter_code')" />
        </b-col>
    </b-row>
</template>
<script>
import filterNumberOptions from '@/utils/constants/filerConstants/numberParametrs';
export default {
    props: ['value', 'field'],
    name: 'code_doc',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            Options: filterNumberOptions
        }
    },
    methods: {
		updateModel(value, name) {
		 if (name === 'filter_code' || name === 'filter_code_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
		
	  },
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'code' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_code';
            this.updateModel(value, name);
        }
    }
}
</script>