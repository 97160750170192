<template>
	<b-row>
		 <b-col cols="2">
			  <p>{{field.label}}</p>
		 </b-col>
		 <b-col cols="5">
			  <b-form-select size="sm" :options="conditionOptions" :value="value.filter_dst_condition"
					@input="updateModel($event, 'filter_dst_condition')"/>
		 </b-col>
		 <b-col cols="5">
			  <b-input :value="value.filter_dst"
					placeholder="Введите номер телефона"
					@input="updateModel($event, 'filter_dst')"
					size="sm"
				/>
		 </b-col>
	</b-row>
</template>
<script>
import filterOptions from '@/utils/constants/filerConstants/parametrs';
export default {
  props: ['value','field'],
  name: 'src',
  model: {
		prop: 'value',
		event: 'change'
  },
  data() {
		return {
			conditionOptions: filterOptions
		}
  },
  methods: {
	updateModel(value, name) {
		 if (name === 'filter_dst' || name === 'filter_dst_condition') {
			if (value === '') {
			  value = null;
			}
			this.$emit('change', { val: value, field: name });
		 }
 
		 this.$store.state.filterPlus.filterPlus = null;
		
	  },
  },
  mounted() {
		if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'dst' && this.$store.state.filterPlus.addFilterPlus) {
			 const value = this.$store.state.filterPlus.filterPlus;
			 const name = 'filter_dst';
			 this.updateModel(value, name);
		}
  }
}
</script>