<template>
<div>
    <b-row class="mt-1">
        <b-col cols="2">
            <p>Статус</p>
        </b-col>
        <b-col cols="10">
            <b-form-select size="sm" :options="this.$route.name == 'addresses/addresses' || this.$route.name == 'addresses/fast/addresses' || this.$route.name == 'addresses/streets' || this.$route.name == 'car-settings/brands'  || this.$route.name == 'car-settings/car-colors' || this.$route.name == 'settings-order/cancel-reason' || this.$route.name == 'main/tariff/client' || this.$route.name ==  'divisions' || this.$route.name == 'driver-profile-causes' || this.$route.name == 'car-settings/model-cars'  ? statusOption : statusOptions" :value="value.filter_is_active_id"
                @input="updateModel($event, filterName())" />
        </b-col>
    </b-row>
</div>
</template>
<script>
export default {
    props: ['value'],
    name: 'status',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            statusOptions: [
                { value: false, text: 'Не активен',},
                { value: true, text: 'Активен' },
            ],
            statusOption: [
                { value: 0, text: 'Не активен',},
                { value: 1, text: 'Активен' },
            ],
            currentRoute: this.$route.fullPath,
        }
    },
    methods: {
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        },
        filterName(){
            if (this.$route.name == 'addresses/addresses' || this.$route.name == 'addresses/fast/addresses' || this.$route.name == 'addresses/streets' || this.$route.name == 'car-settings/car-colors' || this.$route.name == 'car-settings/brands' || this.$route.name == 'settings-order/cancel-reason'|| this.$route.name == 'main/tariff/client' || this.$route.name ==  'divisions' || this.$route.name == 'driver-profile-causes' || this.$route.name == 'car-settings/model-cars') {
                return 'filter_is_active'
            } else {
                return 'filter_status_id'
            }
        }
    },
    watch: {
        $route(to, from) {
            this.currentRoute = to.fullPath;
        },
    }
}
</script>