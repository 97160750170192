export default {
    namespaced: true,
    state:{
        orders: [],
    },
    mutations:{
        SET_ORDERS(state, orders) {
            state.orders = orders
        },
    },
    actions: {},
    getters: {},
}